import styled from "@emotion/styled";
import ProfileImagePlaceholder from "./ProfileImagePlaceholder.png";
import {
  Container,
  Flex,
  Image,
  Grid,
  Center,
  MediaQuery,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { useOs } from "@mantine/hooks";
import { AxiosData } from "./App";
import PawIcon from "./icons/img1.png";
import MusicIcon from "./icons/img2.png";
import ClockHeartIcon from "./icons/img3.png";
import MapPin from "./icons/img4.png";
import HeartIcon from "./icons/img5.png";
import LanguageIcon from "./icons/img6.png";
import BallonIcon from "./icons/img7.png";
import LightBulbIcon from "./icons/img8.png";

interface BioContentProps {
  data?: AxiosData;
  profilePic?: AxiosData["profile_image"];
  isVerifiedProfilePic?: AxiosData["isVerifiedProfilePic"];
}

const BioContent: React.FC<BioContentProps> = ({
  data,
  profilePic,
  isVerifiedProfilePic,
}) => {
  // const mobileView = useMediaQuery("(max-width: 414px)");
  const isMobileView = useOs();
  const MOBILE_VIEWS = ["ios", "android"];
  const migratedProfilePic = data?.isMigratedOver
    ? profilePic !== null
      ? profilePic?.replace("/raw/", "/image/") + "?w=359&h=460"
      : ProfileImagePlaceholder
    : profilePic;
  return (
    <MediaQuery largerThan="sm" styles={{ marginTop: "-40px" }}>
      <Container size="lg">
        {MOBILE_VIEWS.includes(isMobileView) && (
          <Grid grow mb="200px">
            <Grid.Col span={12} md={2}>
              <Image
                radius="lg"
                height={460}
                alt="Technician Profile Image"
                src={
                  (isVerifiedProfilePic && profilePic) ||
                  ProfileImagePlaceholder
                }
              />
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Center mt={240} h={100} mx="auto">
                <Flex direction="column" c="#3e4066" mb={50}>
                  <Title size="h1" fw="bold" mb={20}>
                    Short Bio
                  </Title>
                  <SimpleGrid
                    cols={2}
                    spacing="xs"
                    verticalSpacing="xs"
                    breakpoints={[
                      { maxWidth: "48rem", cols: 2, spacing: "xs" },
                      { maxWidth: "36rem", cols: 1, spacing: "xs" },
                    ]}
                  >
                    {data?.bio.born && (
                      <Row>
                        <img src={BallonIcon} alt="Balloon icon" />
                        <Text ml={15}>Born in the: {data?.bio.born}</Text>
                      </Row>
                    )}
                    {data?.bio.pets && (
                      <Row>
                        <img src={PawIcon} alt="paw icon" />
                        <Text ml={15}>Pets: {data?.bio.pets}</Text>
                      </Row>
                    )}
                    {data?.bio.time && (
                      <Row>
                        <img
                          src={ClockHeartIcon}
                          alt="clock with a heart icon"
                        />
                        <Text ml={15}>
                          I spend too much time: {data.bio.time}
                        </Text>
                      </Row>
                    )}
                    {data?.bio.fact && (
                      <Row>
                        <img src={LightBulbIcon} alt="lightbulb icon" />
                        <Text ml={15}>Fun fact: {data?.bio.fact}</Text>
                      </Row>
                    )}
                    {data?.bio.lives && (
                      <Row>
                        <img src={MapPin} alt="Map pin icon" />
                        <Text ml={15}>Hometown: {data?.bio.lives}</Text>
                      </Row>
                    )}
                    {data?.bio.obsessions && (
                      <Row>
                        <img src={HeartIcon} alt="heart icon" />
                        <Text ml={15}>
                          I'm obsessed: {data?.bio.obsessions}
                        </Text>
                      </Row>
                    )}
                    {data?.bio.song && (
                      <Row>
                        <img src={MusicIcon} alt="music icon" />
                        <Text ml={15}>Favorite song: {data?.bio.song}</Text>
                      </Row>
                    )}
                    {data?.bio.language && (
                      <Row>
                        <img src={LanguageIcon} alt="language icon" />
                        <Text ml={15}>Speaks: {data?.bio.language}</Text>
                      </Row>
                    )}
                  </SimpleGrid>
                </Flex>
              </Center>
            </Grid.Col>
          </Grid>
        )}
        {!MOBILE_VIEWS.includes(isMobileView) && (
          <Grid grow>
            <Grid.Col span={12} md={6}>
              <Center mt={240} h={100} mx="auto">
                <Flex direction="column" c="#3e4066" mb={50}>
                  <Title size="h1" fw="bold" mb={20}>
                    Short Bio
                  </Title>
                  <SimpleGrid
                    cols={2}
                    spacing="xs"
                    verticalSpacing="xs"
                    breakpoints={[
                      { maxWidth: "48rem", cols: 2, spacing: "xs" },
                      { maxWidth: "36rem", cols: 1, spacing: "xs" },
                    ]}
                  >
                    {data?.bio.born && (
                      <Row>
                        <img src={BallonIcon} alt="Balloon icon" />
                        <Text ml={15}>Born in the: {data?.bio.born}</Text>
                      </Row>
                    )}
                    {data?.bio.pets && (
                      <Row>
                        <img src={PawIcon} alt="paw icon" />
                        <Text ml={15}>Pets: {data?.bio.pets}</Text>
                      </Row>
                    )}
                    {data?.bio.time && (
                      <Row>
                        <img
                          src={ClockHeartIcon}
                          alt="clock with a heart icon"
                        />
                        <Text ml={15}>
                          I spend too much time: {data.bio.time}
                        </Text>
                      </Row>
                    )}
                    {data?.bio.fact && (
                      <Row>
                        <img src={LightBulbIcon} alt="lightbulb icon" />
                        <Text ml={15}>Fun fact: {data?.bio.fact}</Text>
                      </Row>
                    )}
                    {data?.bio.lives && (
                      <Row>
                        <img src={MapPin} alt="Map pin icon" />
                        <Text ml={15}>Hometown: {data?.bio.lives}</Text>
                      </Row>
                    )}
                    {data?.bio.obsessions && (
                      <Row>
                        <img src={HeartIcon} alt="heart icon" />
                        <Text ml={15}>
                          I'm obsessed: {data?.bio.obsessions}
                        </Text>
                      </Row>
                    )}
                    {data?.bio.song && (
                      <Row>
                        <img src={MusicIcon} alt="music icon" />
                        <Text ml={15}>Favorite song: {data?.bio.song}</Text>
                      </Row>
                    )}
                    {data?.bio.language && (
                      <Row>
                        <img src={LanguageIcon} alt="language icon" />
                        <Text ml={15}>Speaks: {data?.bio.language}</Text>
                      </Row>
                    )}
                  </SimpleGrid>
                </Flex>
              </Center>
            </Grid.Col>
            <Grid.Col span={12} md={2}>
              <Image
                radius="lg"
                height={460}
                alt="Technician Profile Image"
                src={
                  (isVerifiedProfilePic && migratedProfilePic) ||
                  ProfileImagePlaceholder
                }
              />
            </Grid.Col>
          </Grid>
        )}
      </Container>
    </MediaQuery>
  );
};

export default BioContent;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
