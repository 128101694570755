import styled from "@emotion/styled";
import { IconMail, IconMapPin } from "@tabler/icons-react";
import { Container, Flex, Grid, Group, Text, Title } from "@mantine/core";

function ContactSection() {
  return (
    <Container size="lg" pt={150} pb={150} c="#3e4066">
      <Grid>
        <Grid.Col md={4} span={12}>
          <Title size="h1" fw="bold">
            Wake up to your Dream Team with Conneq
          </Title>
        </Grid.Col>
        <Grid.Col md={4} span={12} ml="auto">
          <Group mb={20}>
            <IconMapPin height={25} />
            <Text size="lg" ml={10}>
              3790 El Camino Real #1012
              <br></br>
              Palo Alto, CA 94306
            </Text>
          </Group>
          <Group>
            <IconMail height={25} />
            <Text size="lg" ml={10}>
              support@conneq.com
            </Text>
          </Group>
        </Grid.Col>
      </Grid>
      <LocationContainer
        gap="xl"
        justify="flex-start"
        align="flex-start"
        direction="row"
      >
        <Text fw={600} mr={30} fz={12}>
          Conneq is available in the following cities
        </Text>
        <Group>
          <IconMapPin height={20} />
          <Text fz={12}>Boston</Text>
        </Group>
        <Group>
          <IconMapPin height={20} />
          <Text fz={12}>Los Angeles</Text>
        </Group>
        <Group>
          <IconMapPin height={20} />
          <Text fz={12}>New York</Text>
        </Group>
        <Group>
          <IconMapPin height={20} />
          <Text fz={12}>San Francisco</Text>
        </Group>
        <Group>
          <IconMapPin height={20} />
          <Text fz={12}>Las Vegas</Text>
        </Group>
      </LocationContainer>
    </Container>
  );
}

const LocationContainer = styled(Flex)`
  padding: 30px 0;
  margin: 40px 0;
  border-top: 2px solid #e0e2e5;
  border-bottom: 2px solid #e0e2e5;
`;
export default ContactSection;
