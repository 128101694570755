export const formEmptyState = {
  isMigratedOver: true,
  userId: "",
  email: "",
  bio: {
    born: "",
    fact: "",
    language: "",
    lives: "",
    obsessions: "",
    pets: "",
    song: "",
    time: "",
  },
  tools: [],
  skills: [],
  first: "",
  last: "",
  location: "",
  on_the_job: [],
  portfolio: [],
  profile_image: "",
  isVerifiedProfilePic: false,
  quote: "",
  roles: [],
};


export const AXIOS_DATA = {
  userId: 'Some user ID',
  email: 'test@test.com',
  bio: {
    born: "90's",
    fact: 'some fact about me',
    language: 'English',
    lives: 'Toronto',
    obsessions: 'Dogs, soccer, chess',
    pets: 'I have two dogs',
    song: "Don't Stop Believin'",
    time: 'Learning new things',
  },
  equipments: ['eq 1', 'eq 2'],
  skills: ['skill 1', 'skill 2'],
  first: 'John',
  last: 'Doe',
  location: 'Toronto, Canada',
  on_the_job: [],
  portfolio: [],
  profile_image: '',
  isVerifiedProfilePic: true,
  quote: 'Go Big or Go home',
  roles: ['role1', 'role2'],
}