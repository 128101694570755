import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Edit from "./pages/Edit";
import Confirm from "./pages/Confirm";
import { css, Global } from "@emotion/react";
import { MantineProvider } from "@mantine/core";
import GlobalStyles from "./GlobalStyles";
import Unavailable from "./pages/Unavailable";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        fontFamily: "Montserrat, sans-serif",
        fontFamilyMonospace: "Monaco, Courier, monospace",
        headings: { fontFamily: "Montserrat, sans-serif" },
      }}
    >
      <Global
        styles={css`
          @font-face {
            font-family: "Montserrat";
            src: url(./static/fonts/Montserrat-ExtraLight.woff2) format("woff2"),
              url(./static/fonts/Montserrat-Light.woff) format("woff");
            font-weight: 200;
          }
          @font-face {
            font-family: "Montserrat";
            src: url(./static/fonts/Montserrat-Light.woff2) format("woff2"),
              url(./static/fonts/Montserrat-Light.woff) format("woff");
            font-weight: 300;
          }

          @font-face {
            font-family: "Montserrat";
            src: url(./static/fonts/Montserrat-Regular.woff2) format("woff2"),
              url(./static/fonts/Montserrat-Regular.woff) format("woff");
            font-weight: 400;
          }

          @font-face {
            font-family: "Montserrat";
            src: url(./static/fonts/Montserrat-Medium.woff2) format("woff2"),
              url(./static/fonts/Montserrat-Medium.woff) format("woff");
            font-weight: 500;
          }

          @font-face {
            font-family: "Montserrat";
            src: url(./static/fonts/Montserrat-SemiBold.woff2) format("woff2"),
              url(./static/fonts/Montserrat-SemiBold.woff) format("woff");
            font-weight: 600;
          }

          @font-face {
            font-family: "Montserrat";
            src: url(./static/fonts/Montserrat-Bold.woff2) format("woff2"),
              url(./static/fonts/Montserrat-Bold.woff) format("woff");
            font-weight: 700;
          }
        `}
      />
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Layout />}> */}
          <Route path="/:slug/edit" element={<Edit />} />
          <Route path="/:slug/:gigId" element={<Confirm />} />
          <Route path="/:slug/:gigId/unavailable" element={<Unavailable />} />
          <Route path="/:slug" index element={<Home />} />
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
