import React from "react";
import { Global, css } from "@emotion/react";
import emotionNormalize from "emotion-normalize";

export const fontFamily = css`
  font-family: "Montserrat", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica", "Ubuntu", "Cantarell", "Arial", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
`;

const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      ${emotionNormalize}
      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      /* https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
      html {
        box-sizing: border-box;
      }
      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }
      body {
        ${fontFamily}
        overscroll-behavior: none;
      }
      a {
        text-decoration: none;
      }
    `}
  />
);

export default GlobalStyles;
