import getVideoId from 'get-video-id';
import { AxiosResponse } from 'axios';

export interface VimeoResponse {
  type: string;
  version: string;
  provider_name: string;
  provider_url: string;
  title: string;
  author_name: string;
  author_url: string;
  is_plus: string;
  account_type: string;
  html: string;
  width: number;
  height: number;
  duration: number;
  description: string;
  thumbnail_url: string;
  thumbnail_width: number;
  thumbnail_height: number;
  thumbnail_url_with_play_button: string;
  upload_date: string;
  video_id: number;
  uri: string;
}

export const typeOfUrl = (url: string, vimeo?: AxiosResponse<VimeoResponse>) => {
  // https://stackoverflow.com/a/61662687/20341251
  // Thumbnail for vimeo
  if (getVideoId(url).service === 'vimeo') {
    return {
      url: 'https://player.vimeo.com/video/' + getVideoId(url).id + '?title=0&byline=0&portrait=0&sidedock=0&transparent=0&controls=1&pip=0&byline=0&modestbranding=1&border=0',
      // thumbnail: vimeo?.data.thumbnail_url,
      thumbnail: `https://vumbnail.com/${getVideoId(url).id}.jpg`
    };
  }
  if (getVideoId(url).service === 'youtube') {
    return {
      url: "https://www.youtube.com/embed/" + getVideoId(url).id,
      thumbnail: `https://i.ytimg.com/vi/${getVideoId(url).id}/hqdefault.jpg`,
    };
  }
  if (url.includes("https://www.loom.com/share/") && url.includes("?sid=")) {
    const loomUrl =
      "https://www.loom.com/embed/" +
      url.split("?sid=")[0].split("/share/").pop() + '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
    return {
      url: loomUrl,
      thumbnail: "https://cdn.loom.com/sessions/thumbnails/" +
        url.split("?sid=")[0].split("/share/").pop() +
        "-with-play.gif",
    };
  }
  if (url.includes("https://www.loom.com/share/")) {
    const loomUrl =
      "https://www.loom.com/embed/" + url.split("/share/").pop() + '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
    return {
      url: loomUrl,
      thumbnail: "https://cdn.loom.com/sessions/thumbnails/" +
        url.split("/share/").pop() +
        "-with-play.gif",
    };
  }
  return {
    url: url,
    thumbnail: url,
  };
};

export const isImgLink = (url: string) => {
  if (typeof url !== "string") return false;
  return (
    url.match(/^http[^?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null
  );
};

export const isImgUrl = (url: string) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}