import React from "react";
import styled from "@emotion/styled";
import { IconTrash } from "@tabler/icons-react";
import {
  Lightbox,
  Slide,
  LoomSlide,
  ImageSlide,
} from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import PhotoAlbum, { RenderPhoto } from "react-photo-album";
import Captions from "yet-another-react-lightbox/plugins/captions";
import PlayButton from "../play_button.png";

import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import {
  ActionIcon,
  ActionIconProps,
  Button,
  Container,
  Flex,
  Text,
  TextInput,
  Title,
  Switch,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "./ProfileEdit";
import { VimeoResponse, typeOfUrl } from "../common/common";

import PROCESSING_IMAGE from "../icons/unverified_content.png";
import axios from "axios";
import getVideoId from "get-video-id";

declare module "yet-another-react-lightbox" {
  export interface LoomSlide extends GenericSlide {
    type: "loom";
    src: string;
    thumbnail: string;
    title: string;
    description: string;
    width: number;
    height: number;
    is_verified: boolean;
  }

  interface SlideTypes {
    loom: LoomSlide;
  }
}

interface RenderCustomThumbnail extends RenderPhoto {}

function isLoomSlide(slide: Slide): slide is LoomSlide {
  return slide.type === "loom";
}

interface Props {
  form: UseFormReturnType<FormValues>;
}

const EditPortfolioSection: React.FC<Props> = ({ form }) => {
  const [index, setIndex] = React.useState(-1);
  const [addPortfolio, setAddPortfolio] = React.useState("");
  const [isVideoLink, setIsVideoLink] = React.useState(true);

  const portfolio = form.values.portfolio;

  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  const hasImageSlides = () => {
    if (portfolio?.length === 0 || portfolio?.length === undefined) return [];
    return portfolio
      ?.filter((port) => port.content_type === "image")
      .map((image) => {
        const width = image.width ? image.width * 4 : 1920;
        const height = image.height ? image.height * 4 : 1080;
        return {
          key: image.url,
          src: image.is_verified ? image.url : PROCESSING_IMAGE,
          width,
          height,
          title: image.title,
          is_verified: image.is_verified,
          srcSet: breakpoints.map((breakpoint) => {
            const breakpointHeight = Math.round((height / width) * breakpoint);
            return {
              src: image.is_verified ? image.url : PROCESSING_IMAGE,
              width: breakpoint,
              height: breakpointHeight,
            };
          }),
        };
      });
  };

  const hasVideoSlides = () => {
    if (portfolio?.length === 0 || portfolio?.length === undefined) return [];
    return portfolio
      ?.filter((port) => port.content_type === "video")
      .map((video) => ({
        key: video.url,
        title: video.title,
        type: "loom",
        width: video.width ? video.width : 1920,
        height: video.height ? video.height : 1080,
        src: video.is_verified ? video.url : PROCESSING_IMAGE,
        thumbnail: video.is_verified ? video.thumbnail : PROCESSING_IMAGE,
        is_verified: video.is_verified,
        srcSet: breakpoints.map((breakpoint) => {
          const breakpointHeight = Math.round((1920 / 1080) * breakpoint);
          return {
            src: video.is_verified ? video.thumbnail : PROCESSING_IMAGE,
            width: breakpoint,
            height: breakpointHeight,
          };
        }),
      }));
  };

  const getImageDimensions = async (url: string) => {
    const img = new Image();
    img.src = url;
    await img.decode();
    return { width: img.naturalWidth, height: img.naturalHeight };
  };

  const renderPhoto: RenderCustomThumbnail = ({
    photo,
    layoutOptions,
    imageProps: { alt, style, ...restImageProps },
  }) => {
    return (
      <MediaContainer
        style={{
          border: "2px solid #eee",
          boxSizing: "content-box",
          alignItems: "center",
          width: style?.width,
          padding: `${layoutOptions.padding - 2}px`,
          marginBottom: "10px",
        }}
      >
        <DeleteButton
          radius="lg"
          onClick={() => {
            const mediaIndex = form.values["portfolio"].findIndex(
              (element) => element.url === restImageProps.src
            );

            const unverifiedMediaIndex = form.values["portfolio"].findIndex(
              (element) => element.url === photo.key
            );

            // If media is unverified
            if (mediaIndex > -1) {
              form.removeListItem("portfolio", mediaIndex);
              return;
            }

            // Handle unverified media deletion
            form.removeListItem("portfolio", unverifiedMediaIndex);
          }}
        >
          <IconTrash color="red" width="2rem" />
        </DeleteButton>
        {/* @ts-expect-error */}
        {photo.type === "loom" &&
          !photo.src.includes("https://www.loom.com") && (
            <img
              alt="play-button"
              src={PlayButton}
              style={{
                position: "absolute",
                top: "43%",
                left: "43%",
                height: "50px",
              }}
            />
          )}
        <img
          alt={alt}
          style={{
            ...style,
            width: "100%",
            padding: 0,
            border: 0,
            marginBottom: 0,
            aspectRatio: "unset",
          }}
          {...restImageProps}
        />
      </MediaContainer>
    );
  };

  return (
    <>
      <Container mt={100} size="lg">
        <Flex justify="space-between" mb={20}>
          <Flex direction="column">
            <Title size="h1" fw="bold" color="#3e4066">
              Portfolio
            </Title>
            <Text size="sm" c="#73769a">
              For longer, informative, higher production value video or photo
              content.
            </Text>
          </Flex>
          <Flex align="center">
            <Switch
              size="sm"
              label="Is a video URL?"
              mr={10}
              labelPosition="left"
              checked={isVideoLink}
              onChange={(e) => setIsVideoLink(e.currentTarget.checked)}
            />
            <TextInput
              placeholder="Add portfolio"
              radius="md"
              label=""
              value={addPortfolio}
              w={300}
              mr={5}
              onChange={(e) => setAddPortfolio(e.currentTarget.value)}
            />
            <Button
              onClick={async () => {
                let vimeoData;
                if (addPortfolio.includes("https://vimeo.com/")) {
                  // https://developer.vimeo.com/api/oembed/videos
                  vimeoData = await axios.get<VimeoResponse>(
                    `https://vimeo.com/api/oembed.json?url=https%3A%2F%2Fvimeo.com%2F${
                      getVideoId(addPortfolio).id
                    }`
                  );
                }
                form.insertListItem(
                  "portfolio",
                  !isVideoLink
                    ? {
                        content_type: "image",
                        is_verified: false,
                        thumbnail: typeOfUrl(addPortfolio).thumbnail,
                        title: "",
                        width: (
                          await getImageDimensions(typeOfUrl(addPortfolio).url)
                        ).width,
                        height: (
                          await getImageDimensions(typeOfUrl(addPortfolio).url)
                        ).height,
                        url: typeOfUrl(addPortfolio).url,
                      }
                    : {
                        content_type: "video",
                        is_verified: false,
                        thumbnail: typeOfUrl(addPortfolio, vimeoData).thumbnail,
                        title: "",
                        url: typeOfUrl(addPortfolio, vimeoData).url,
                      }
                );
                setAddPortfolio("");
              }}
              disabled={!addPortfolio}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      </Container>
      <Container size="lg">
        <PhotoAlbum
          layout="masonry"
          spacing={20}
          photos={[...hasVideoSlides(), ...hasImageSlides()]}
          renderPhoto={renderPhoto}
          onClick={({ index }) => setIndex(index)}
          columns={(containerWidth) => {
            if (containerWidth < 799) return 2;
            if (containerWidth < 800) return 3;
            return 3;
          }}
        />
        <Lightbox
          index={index}
          slides={[...hasVideoSlides(), ...hasImageSlides()]}
          plugins={[Thumbnails, Captions]}
          open={index >= 0}
          close={() => setIndex(-1)}
          render={{
            slide: ({ slide, rect }) =>
              isLoomSlide(slide) ? (
                <iframe
                  width={Math.min(
                    slide.width,
                    rect.width,
                    (slide.width * rect.height) / slide.height
                  )}
                  height={Math.min(
                    slide.height,
                    rect.height,
                    (slide.height * rect.width) / slide.width
                  )}
                  src={slide.src}
                  title={slide.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : undefined,
            thumbnail: ({ slide, ...rest }) =>
              isLoomSlide(slide) ? (
                <ImageSlide slide={{ src: slide.thumbnail }} {...rest} />
              ) : undefined,
          }}
        />
      </Container>
    </>
  );
};

interface ActionButtonProps extends ActionIconProps {
  onClick: () => void;
}

const DeleteButton = styled(ActionIcon)<ActionButtonProps>`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const MediaContainer = styled.div`
  position: relative;
  background: #cffbc4;
  display: flex;
  align-items: center;
`;

export default EditPortfolioSection;
