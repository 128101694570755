export const GIG_STEP_NAME = "gigs";
export const CONTACT_STEP_NAME = "profile";
export const ROLE_STEP_NAME = "swag";

export type BookingStepName =
  | typeof CONTACT_STEP_NAME
  | typeof GIG_STEP_NAME
  | typeof ROLE_STEP_NAME;

export const GIG_ROLES = [
  {
    "id": "dbf49b80-5688-442f-8fab-141257e3177d",
    "name": "A1"
  },
  {
    "id": "b0ac1e9c-0eca-4249-9469-3f59b58d8617",
    "name": "A2"
  },
  {
    "id": "81906457-f6d2-44cd-b685-4502a96f807d",
    "name": "V1"
  },
  {
    "id": "d2d78471-45a3-4709-b092-fae325a6d469",
    "name": "V2"
  },
  {
    "id": "bd17919d-99db-462c-bfd1-5e3a67d419a6",
    "name": "Graphics Op (GFX)"
  },
  {
    "id": "dde9adaf-4190-46fa-9164-6da8185170ed",
    "name": "Lighting Director (LD)"
  },
  {
    "id": "02f778c6-b1a9-48f6-83e2-a36116dd0490",
    "name": "L2"
  },
  {
    "id": "fc263bf2-0c05-40a9-bb04-693f99a04317",
    "name": "Video Wall Tech (Lead)"
  },
  {
    "id": "b0f7f5f9-5ee7-44d5-9a3e-7272b2c30800",
    "name": "Video Wall Assist"
  },
  {
    "id": "1d3c901b-491c-45e2-b16d-d78fc5cf4465",
    "name": "General AV"
  },
  {
    "id": "06aae3ae-cd2e-4235-a663-ec85be541011",
    "name": "Stage Hand"
  },
  {
    "id": "c19ecd6f-1bdc-4531-9100-6d07a4171a71",
    "name": "Livestream Op"
  },
  {
    "id": "51e7965c-107c-43d7-95bd-447d89a8ecf9",
    "name": "Camera Operator"
  },
  {
    "id": "f6a98c46-ece0-4740-8a44-4e2dd1ba80d7",
    "name": "Camera Operator (Robotic)"
  },
  {
    "id": "d7a9266f-99b6-4c81-91f5-9c92ed1dadc1",
    "name": "Show Caller"
  },
  {
    "id": "7aabba8c-b48d-48d3-b42f-3c5499a84490",
    "name": "Editor"
  },
  {
    "id": "58a6604c-f0c4-4a82-8f5f-5bfee3e544b1",
    "name": "Technical Director"
  },
  {
    "id": "44791d9f-7937-4aee-a310-f94b746bb507",
    "name": "Technical Director (Switch Operator)"
  },
  {
    "id": "b30247a6-2c9a-4dfc-bbc2-d4a9caf37de4",
    "name": "Technical Producer"
  },
  {
    "id": "1ed0dd57-5df3-4e60-979b-9a2e771ad9ff",
    "name": "Post-Production Supervisor"
  },
  {
    "id": "837a9e9b-ef29-4a83-b508-9ffa156f39e7",
    "name": "Producer"
  },
  {
    "id": "2c871ff5-bad7-414f-b7fd-37270ee5d90e",
    "name": "Production Assistant (PA)"
  },
  {
    "id": "4d59366b-2937-4233-bc59-30577ccbad70",
    "name": "Director"
  },
  {
    "id": "f8735e81-bc45-4d79-b032-9ee2fd739d64",
    "name": "Frequency Coordinator"
  },
  {
    "id": "8203f8e8-9fdc-4fda-bcdf-ff37cd7aee71",
    "name": "Truck Driver"
  },
  {
    "id": "05941eba-4f35-405b-8138-18e20828def3",
    "name": "Gaffer"
  },
  {
    "id": "5498c8cf-fcab-4c63-9370-feca3fcec36b",
    "name": "Grip"
  },
  {
    "id": "2ac5efd3-5f44-497a-9463-2f56c48c26aa",
    "name": "LED Wall Engineer"
  },
  {
    "id": "0d579d3f-e923-4db0-9b52-af5cae25d86d",
    "name": "Live Stream Op"
  },
  {
    "id": "262ab70b-88b6-4be2-99cb-a48fb346b482",
    "name": "Warehouse Operations Manager"
  },
  {
    "id": "8ffe78eb-7a79-41a7-a762-8e08d06208d1",
    "name": "Tricastor Operator"
  },
  {
    "id": "e2b26cf9-1e9e-42f1-8928-b3578c1a48d9",
    "name": "Drone Pilot"
  },
  {
    "id": "711449d5-f7c7-4956-b1ec-ca9a96d8e2ea",
    "name": "Production Coordinator"
  },
  {
    "id": "78086fc9-7afd-4363-b7d5-ba17f2b6834b",
    "name": "Stage Manager"
  },
  {
    "id": "3612e996-ea3f-49a9-b9c9-fe96d47e163d",
    "name": "Teleprompter Operator"
  },
  {
    "id": "7656cc4a-ea25-435a-be15-c46a8ac33267",
    "name": "Head Camera"
  },
  {
    "id": "113b103f-cb98-4b18-bea8-03efb998a215",
    "name": "Event Marketer"
  },
  {
    "id": "cc10a411-d2a4-4300-b771-ff28ea068870",
    "name": "Consultant"
  }
];

export const US_STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];
