import React from "react";
import { AxiosData } from "../App";
import axios from "axios";
import { LoadingOverlay } from "@mantine/core";
import { formEmptyState } from "../constants/constants";
import { useParams } from "react-router-dom";
import BookingConfirmationPage from "../BookingConfirmationPage";

interface GigData {
  gigById: {
    id: string;
    name: string;
    description: string;
    type: "PHYSICAL" | "HYBRID" | "VIRTUAL";
    location: string;
    timezone: string;
    dates: string[];
    jobs: {
      id: string;
      gigRole: {
        id: string;
        name: string;
      };
      shifts: {
        id: string;
        date: string;
        startsAt: string;
        endsAt: string;
        dailyRate: string;
        notes: string;
      }[];
      dailyBudget: number;
      notes: string;
      paymentTermsTemplate: string;
    }[];
  };
}

export const EMPTY_GIG_DATA: GigData = {
  gigById: {
    id: "empty id",
    name: "",
    description: "",
    type: "VIRTUAL",
    location: "",
    timezone: "",
    dates: [],
    jobs: [],
  },
};

const Confirm = () => {
  const { slug, gigId } = useParams();

  const [gigData, setGigData] = React.useState<GigData>(EMPTY_GIG_DATA);
  const [techData, setTechData] = React.useState<AxiosData>(formEmptyState);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    axios
      .post(
        "https://api.retool.com/v1/workflows/4ca5807a-f8b3-4bdb-a0ed-ed91b7835bf0/startTrigger?workflowApiKey=retool_wk_bba8b483a34e408b89fca906847153eb",
        {
          slug,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const user = response.data;
        setTechData(user);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(() => {
        axios
          .post<{ data: GigData }>(
            "https://api.retool.com/v1/workflows/d893bca6-6239-472d-b7b4-918a6668ba84/startTrigger?workflowApiKey=retool_wk_2082e8f43f084f71a309aaf1015af146",
            {
              gigId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("gigById: ", res.data.data);
            setGigData(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      });
  }, [slug, gigId]);

  if (loading) return <LoadingOverlay visible />;

  return <BookingConfirmationPage gigData={gigData} techData={techData} />;
};

export default Confirm;
