import {
  Container,
  Flex,
  Group,
  SimpleGrid,
  Text,
  TextProps,
} from "@mantine/core";
import styled from "@emotion/styled";

interface Props {
  roleName: string;
  gigName: string;
  numberOfBillableDays: number;
  dailyRateInCents: number;
  serviceFeePercent: number;
}

const BookingDetails: React.FC<Props> = ({
  roleName,
  gigName,
  numberOfBillableDays,
  dailyRateInCents,
  serviceFeePercent,
}) => {
  const subtotal = (dailyRateInCents / 100) * numberOfBillableDays;
  const serviceFeeAmount = subtotal * (serviceFeePercent / 100);
  const estimatedTotal = subtotal + serviceFeeAmount;
  return (
    <StyledSection title="Booking Details">
      <Container size="lg" p={15} pt={25}>
        <SimpleGrid cols={2} mb={25}>
          <Flex direction="column">
            <Text size="xs" fw={700} mb={5} c="#3e4066">
              Role
            </Text>
            <Text mb="xl" c="#73769a">
              {roleName}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text size="xs" fw={700} mb={5} c="#3e4066">
              Gig Name
            </Text>
            <Text mb="xl" c="#73769a">
              {gigName}
            </Text>
          </Flex>
        </SimpleGrid>
        <PricingTableHeader>
          <Text size="sm" fw={500}>
            Freelancer Price Breakdown
          </Text>
        </PricingTableHeader>
        <PricingTable direction="column">
          <LineItem position="apart" c="#73769a">
            <Text size="sm">Daily Rate</Text>
            <Text size="sm">
              &#36;{dailyRateInCents / 100} x {numberOfBillableDays}
              {numberOfBillableDays === 1 ? " day" : " days"}
            </Text>
          </LineItem>
          <LineItem position="apart" c="#73769a">
            <Text size="sm">Subtotal</Text>
            <Text size="sm">&#36;{subtotal}</Text>
          </LineItem>
          <LineItem position="apart" c="#73769a">
            <Text size="sm">
              Service Fee &#40;{serviceFeePercent}&#37;&#41;
            </Text>
            <Text size="sm">&#36;{serviceFeeAmount}</Text>
          </LineItem>
          <Group position="apart" p="14px 0 0" c="#3e4066">
            <Text size="sm">Estimated Total</Text>
            <Text size="sm">&#36;{estimatedTotal}</Text>
          </Group>
        </PricingTable>
      </Container>
    </StyledSection>
  );
};

const PricingTable = styled(Flex)`
  padding: 5px 24px 24px;
  border: 1px solid #e1e1e7;
  border-radius: 0 0 8px 8px;
`;

const PricingTableHeader = styled(Group)`
  padding: 8px 15px;
  background: #f5f8fa;
  color: #3e4066;
  border: 1px solid #e1e1e7;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
`;

const LineItem = styled(Group)`
  padding: 14px 0;
  border-bottom: 1px solid #e1e1e7;
`;

export default BookingDetails;

interface StyledSectionProps {
  title: string;
  children: any;
}

export const StyledSection: React.FC<StyledSectionProps> = ({
  title,
  children,
}) => {
  return (
    <BorderedContainer>
      <ContainerTitle size="xs" fw={700} ml={15} mr={15} bg="white" c="#6a6d93">
        {title}
      </ContainerTitle>
      {children}
    </BorderedContainer>
  );
};

const ContainerTitle = styled(Text)<TextProps>`
  position: absolute;
  top: -10px;
  padding-left: 15px;
  padding-right: 15px;
`;

const BorderedContainer = styled.div`
  position: relative;
  border: 1px solid #e1e1e7;
  border-radius: 8px;
  margin-top: 45px;
`;
