import React from "react";
import styled from "@emotion/styled";
import {
  Lightbox,
  Slide,
  LoomSlide,
  ImageSlide,
} from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import PhotoAlbum, { RenderPhoto } from "react-photo-album";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Counter from "yet-another-react-lightbox/plugins/counter";

import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { Container, Title } from "@mantine/core";
import { AxiosData } from "./App";
import PlayButton from "./play_button.png";
import { typeOfUrl } from "./common/common";

declare module "yet-another-react-lightbox" {
  export interface LoomSlide extends GenericSlide {
    type: "loom";
    src: string;
    thumbnail: string;
    title: string;
    description: string;
    width: number;
    height: number;
  }

  interface SlideTypes {
    loom: LoomSlide;
  }
}

interface RenderCustomThumbnail extends RenderPhoto {}

function isLoomSlide(slide: Slide): slide is LoomSlide {
  return slide.type === "loom";
}

interface Props {
  data?: AxiosData["portfolio"];
  isMigratedOver?: boolean;
}

const PortfolioSection: React.FC<Props> = ({ data, isMigratedOver }) => {
  const [index, setIndex] = React.useState(-1);

  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  const hasImageSlides = () => {
    if (data?.length === 0 || data?.length === undefined) return [];
    return data
      ?.filter((port) => port.content_type === "image" && port.is_verified)
      .map((image) => {
        if (isMigratedOver) {
          return {
            src:
              typeOfUrl(image.url).url.replace("/raw/", "/image/") + "?w=353",
            width: 353,
            height: 1080,
            srcSet: breakpoints.map((breakpoint) => {
              const breakpointHeight = Math.round((1080 / 353) * breakpoint);
              return {
                src:
                  typeOfUrl(image.url).url.replace("/raw/", "/image/") +
                  "?w=353",
                width: breakpoint,
                height: breakpointHeight,
              };
            }),
          };
        }
        const width = image.width ? image.width * 4 : 1920;
        const height = image.height ? image.height * 4 : 1080;
        return {
          src: typeOfUrl(image.url).url,
          width,
          height,
          srcSet: breakpoints.map((breakpoint) => {
            const breakpointHeight = Math.round((height / width) * breakpoint);
            return {
              src: typeOfUrl(image.url).url,
              width: breakpoint,
              height: breakpointHeight,
            };
          }),
        };
      });
  };

  const hasVideoSlides = () => {
    if (data?.length === 0 || data?.length === undefined) return [];
    return data
      ?.filter((port) => port.content_type === "video" && port.is_verified)
      .map((video) => {
        return {
          title: video.title,
          type: "loom",
          width: video.width ? video.width : 1920,
          height: video.height ? video.height : 1080,
          src: typeOfUrl(video.url).url,
          thumbnail: video.thumbnail ?? typeOfUrl(video.url).thumbnail,
          srcSet: breakpoints.map((breakpoint) => {
            const breakpointHeight = Math.round((1920 / 1080) * breakpoint);
            return {
              src: video.thumbnail ?? typeOfUrl(video.url).thumbnail,
              width: breakpoint,
              height: breakpointHeight,
            };
          }),
        };
      });
  };

  const renderPhoto: RenderCustomThumbnail = ({
    layoutOptions,
    photo,
    imageProps: { alt, style, ...restImageProps },
  }) => {
    return (
      <MediaContainer
        style={{
          border: "2px solid #eee",
          boxSizing: "content-box",
          alignItems: "center",
          width: style?.width,
          padding: `${layoutOptions.padding - 2}px`,
          marginBottom: "10px",
          backgroundColor: "transparent",
          borderRadius: "0.5rem",
        }}
      >
        {/* @ts-expect-error */}
        {photo.type === "loom" &&
          !photo.src.includes("https://www.loom.com") && (
            <img
              alt="play-button"
              src={PlayButton}
              style={{
                position: "absolute",
                top: "43%",
                left: "43%",
                height: "50px",
              }}
            />
          )}
        <img
          alt={alt}
          style={{
            ...style,
            width: "100%",
            padding: 0,
            border: 0,
            marginBottom: 0,
            borderRadius: "0.5rem",
            aspectRatio: "unset",
          }}
          {...restImageProps}
        />
      </MediaContainer>
    );
  };

  return (
    <>
      <Container size="lg" mt={100}>
        <Title size="h1" fw="bold" mb={20} style={{ color: "#3e4066" }}>
          Portfolio
        </Title>
        <PhotoAlbum
          layout="masonry"
          spacing={20}
          photos={[...hasVideoSlides(), ...hasImageSlides()]}
          renderPhoto={renderPhoto}
          onClick={({ index }) => setIndex(index)}
          columns={(containerWidth) => {
            if (containerWidth < 799) return 2;
            if (containerWidth < 800) return 3;
            return 3;
          }}
        />
        <Lightbox
          index={index}
          slides={[...hasVideoSlides(), ...hasImageSlides()]}
          plugins={[Thumbnails, Captions, Counter]}
          open={index >= 0}
          close={() => setIndex(-1)}
          render={{
            slide: ({ slide, rect }) =>
              isLoomSlide(slide) ? (
                <RoundedFrame>
                  <iframe
                    width={Math.min(
                      slide.width,
                      rect.width,
                      (slide.width * rect.height) / slide.height
                    )}
                    height={Math.min(
                      slide.height,
                      rect.height,
                      (slide.height * rect.width) / slide.width
                    )}
                    src={slide.src}
                    title={slide.title}
                    style={{ borderWidth: "0" }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </RoundedFrame>
              ) : undefined,
            thumbnail: ({ slide, ...rest }) =>
              isLoomSlide(slide) ? (
                <ImageSlide slide={{ src: slide.thumbnail }} {...rest} />
              ) : undefined,
          }}
        />
      </Container>
    </>
  );
};

export default PortfolioSection;

const MediaContainer = styled.div`
  position: relative;
  background: #cffbc4;
  display: flex;
  align-items: center;
`;

// How to have rounded borders for iframe
// https://stackoverflow.com/a/36110063/20341251
const RoundedFrame = styled.div`
  display: block;
  overflow: hidden;
  /* height: 500px; */
  border-radius: 0.5rem;
  transform: translateZ(0px);
  border: 0;
`;
