import React from "react";
import { Accordion, Badge, Container, Title } from "@mantine/core";

interface Skill {
  id: string;
  name: string;
}

interface SkillsSectionProps {
  skills?: Skill[];
}

const SkillsSection: React.FC<SkillsSectionProps> = ({ skills }) => {
  return (
    <Container size="md" pt={60}>
      <Accordion
        chevronPosition="left"
        styles={{
          item: {
            // styles added to all items
            borderRadius: "15px",
            backgroundColor: "#f1f2f4",
            border: `none`,

            // styles added to expanded item
            "&[data-active]": {
              backgroundColor: "#f1f2f4",
            },
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
          },
          control: {
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
            svg: {
              width: "2rem",
            },
          },
        }}
      >
        <Accordion.Item value="skills">
          <Accordion.Control>
            <Title size="h1" fw={300} color={"#3e4066"}>
              Skills
            </Title>
          </Accordion.Control>
          <Accordion.Panel>
            {skills?.map((skill) => (
              <Badge
                key={skill.id}
                color="green.6"
                size="xl"
                variant="filled"
                mr={10}
                mb={10}
                pt={20}
                pb={20}
                sx={{
                  color: "#3e4066",
                  textTransform: "capitalize",
                  backgroundColor: "#EEFFF0",
                  fontWeight: 500,
                  minWidth: "100px",
                }}
              >
                {skill.name}
              </Badge>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default SkillsSection;
