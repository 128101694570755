import React from "react";
import ProfileEdit from "../ProfileEdit/ProfileEdit";
import { AxiosData } from "../App";
import axios from "axios";
import { LoadingOverlay } from "@mantine/core";
import { formEmptyState } from "../constants/constants";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { slug } = useParams();

  const [data, setData] = React.useState<AxiosData>(formEmptyState);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    axios
      .post(
        // V1 API
        // "https://api.retool.com/v1/workflows/4ca5807a-f8b3-4bdb-a0ed-ed91b7835bf0/startTrigger?workflowApiKey=retool_wk_bba8b483a34e408b89fca906847153eb",
        "https://api.retool.com/v1/workflows/21997429-4eb4-45ad-b847-d131d059ad54/startTrigger?workflowApiKey=retool_wk_08cbedac29c44f7ab892e9d9ac1b61a3",
        {
          slug,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const user = response.data;
        setData(user);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }, [slug]);

  if (loading) return <LoadingOverlay visible />;

  return <ProfileEdit data={data} />;
};

export default Edit;
