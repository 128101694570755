import { Avatar, Button, Flex, Modal, Container, Title } from "@mantine/core";
import { IconUserCheck } from "@tabler/icons-react";

import BookingDetails from "./BookingDetails";
import ShiftDetails from "./ShiftDetails";
import ContactDetails from "./ContactDetails";
import AddressDetails from "./AddressDetails";
import { AxiosData } from "../App";

interface Props {
  data: AxiosData;
  /**
   * Whether or not the modal is currently open.
   */
  isOpen: boolean;
  /**
   * Invoked when the user completes the final required step.
   */
  onFinished: () => unknown;
  /**
   * Invoked when the user explicitly closes the modal.
   */
  onClose: () => unknown;
}

const ConfirmBookingModal: React.FC<Props> = ({
  data,
  isOpen,
  onClose,
  onFinished,
}) => {
  function resetState() {
    onClose();
  }

  return (
    <Modal
      opened={isOpen}
      size={500}
      radius="lg"
      closeOnEscape={false}
      closeOnClickOutside={false}
      onClose={() => resetState()}
    >
      <Container size="lg" p={15} pt={0}>
        <Flex align="center" direction="column" mb="md">
          <Avatar bg="#f3f4f6" radius="xl" size="lg" mb="md">
            <IconUserCheck color="#6a6d93" size="2.5rem" />
          </Avatar>
          <Title size="h1" fw={500} mb={0} c="#3e4066">
            Confirm Booking
          </Title>
        </Flex>
        <BookingDetails
          gigName="Emma's Gig"
          roleName="Technical Director (Switch Operator)"
          numberOfBillableDays={3}
          dailyRateInCents={50000}
          serviceFeePercent={20}
        />
        <ShiftDetails shifts={[]} />
        <AddressDetails address={data.location} />
        <ContactDetails
          email="john_smith@gmail.com"
          name={`${data.first} ${data.last}`}
          phoneNumber="15555555555"
        />
        <Flex align="center" direction="column">
          <Button
            size="lg"
            mt="xl"
            radius="md"
            styles={(theme) => ({
              root: {
                color: "#3e4066",
                backgroundColor: "#00f85d",
                "&:hover": theme.fn.hover({
                  backgroundColor: theme.fn.lighten("#00f85d", 0.3),
                }),
              },
            })}
            onClick={() => {}}
          >
            Book Technician
          </Button>
        </Flex>
      </Container>
    </Modal>
  );
};

export default ConfirmBookingModal;
