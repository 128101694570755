import React from "react";
import ContactSection from "../ContactSection";
import { useForm } from "@mantine/form";
import { useParams } from "react-router-dom";

import EditNameContent from "./EditNameContent";
import EditBioContent from "./EditBioContent";
import { AxiosData } from "../App";
import EditOnTheJobContent from "./EditOnTheJobContent";
import EditSkillsSection from "./EditSkillsSection";
import EditRoleSection from "./EditRoleSection";
import EditToolSection from "./EditToolSection";
import axios from "axios";
import EditPortfolioSection from "./EditPortfolioSection";

interface Props {
  data: AxiosData;
}

export interface FormValues {
  bio: {
    born: string;
    fact: string;
    language: string;
    lives: string;
    obsessions: string;
    pets: string;
    song: string;
    time: string;
  };
  tools: string[];
  skills: string[];
  location: string;
  on_the_job: {
    content_type: "image" | "video";
    thumbnail: string;
    title: string;
    url: string;
    is_verified: boolean;
  }[];
  portfolio: {
    content_type: "image" | "video";
    thumbnail: string;
    title: string;
    url: string;
    is_verified: boolean;
    width: number;
    height: number;
  }[];
  profile_image: string;
  isVerifiedProfilePic: boolean;
  quote: string;
  roles: string[];
}

const ProfileEdit: React.FC<Props> = ({ data }) => {
  const { slug } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const pwd = urlParams.get("pwd");
  const [isLoadingUserProfile, setIsLoadingUserProfile] = React.useState(false);

  const form = useForm({
    initialValues: {
      bio: {
        born: data?.bio.born,
        fact: data?.bio.fact ?? "",
        language: data?.bio.language ?? "",
        lives: data?.bio.lives ?? "",
        obsessions: data?.bio.obsessions ?? "",
        pets: data?.bio.pets ?? "",
        song: data?.bio.song ?? "",
        time: data?.bio.time ?? "",
      },
      tools: data?.tools,
      skills: data?.skills,
      location: data?.location,
      on_the_job: data?.on_the_job,
      portfolio: data?.portfolio,
      profile_image: data?.profile_image,
      isVerifiedProfilePic: data?.isVerifiedProfilePic,
      quote: data?.quote ?? "",
      roles: data?.roles,
    },
  });

  const submitToRetool = (values: FormValues) => {
    setIsLoadingUserProfile(true);
    axios
      .post(
        "https://api.retool.com/v1/workflows/a2c40085-9541-488e-815d-9c2c7727e665/startTrigger?workflowApiKey=retool_wk_7bdcb289ab63496185b268534f8f741f",
        {
          slug,
          techId: pwd,
          profile_image: values.profile_image,
          isVerifiedProfilePic: values.isVerifiedProfilePic,
          quote: values.quote,
          location: values.location,
          bio: {
            born: values.bio.born,
            fact: values.bio.fact,
            language: values.bio.language,
            lives: values.bio.lives,
            obsessions: values.bio.obsessions,
            pets: values.bio.pets,
            song: values.bio.song,
            time: values.bio.time,
          },
          roles: values.roles,
          skills: values.skills,
          tools: values.tools,
          on_the_job: values.on_the_job,
          portfolio: values.portfolio,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        window.alert("Profile has been updated");
        setIsLoadingUserProfile(false);
        console.log(response);
      })
      .catch(function (error) {
        window.alert("Error, the profile did not update");
        setIsLoadingUserProfile(false);
        console.error(error);
      });
  };

  return (
    <>
      <form
        onSubmit={form.onSubmit((values) => {
          console.log("form values: ", values);
          submitToRetool(values);
        })}
      >
        <EditNameContent data={data} isLoading={isLoadingUserProfile} />
        <EditBioContent
          data={data?.bio}
          profilePic={data?.profile_image}
          isVerifiedProfilePic={data?.isVerifiedProfilePic}
          form={form}
        />
        <EditOnTheJobContent data={data?.on_the_job} form={form} />
        <EditRoleSection form={form} />
        <EditSkillsSection form={form} />
        <EditToolSection form={form} />
        <EditPortfolioSection form={form} />
      </form>
      <ContactSection />
    </>
  );
};

export default ProfileEdit;
