import React from "react";

import { Carousel } from "@mantine/carousel";
import { Container, Image, Title } from "@mantine/core";
import { AxiosData } from "./App";
import ImagePlaceHolder from "./ImagePlaceholder.jpeg";
import { typeOfUrl } from "./common/common";
import styled from "@emotion/styled";

interface Props {
  data?: AxiosData["on_the_job"];
  isMigratedOver?: boolean;
}

const OnTheJobContent: React.FC<Props> = ({ data, isMigratedOver }) => {
  const slides = data
    ?.filter((dFilter) => dFilter.is_verified === true)
    .map((d, index) => {
      const migratedImgUrl = isMigratedOver
        ? d.url.replace("/raw/", "/image/") + "?w=355&h=500"
        : typeOfUrl(d.url).url;

      if (d.content_type === "video") {
        return (
          <Carousel.Slide key={index}>
            <RoundedFrame>
              <iframe
                src={typeOfUrl(d.url).url}
                height={500}
                style={{ width: "100%", border: "none", aspectRatio: "9/16" }}
                allowFullScreen
                title={d.title}
              />
            </RoundedFrame>
          </Carousel.Slide>
        );
      }
      return (
        <Carousel.Slide key={index}>
          <Image radius="md" src={migratedImgUrl} alt="1" height={500} />
        </Carousel.Slide>
      );
    });

  return (
    <Container size="lg" mt={100}>
      <Title size="h1" fw="bold" mb={40} c="#3e4066">
        On the job
      </Title>
      {/* </Container>
      <Container size="lg"> */}
      <Carousel
        withIndicators
        height={500}
        slideSize="33.333333%"
        slideGap="md"
        loop
        align="start"
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
        ]}
      >
        {data?.length === 0 ? (
          <>
            <Carousel.Slide>
              <Image radius="md" src={ImagePlaceHolder} alt="1" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Image radius="md" src={ImagePlaceHolder} alt="1" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Image radius="md" src={ImagePlaceHolder} alt="1" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Image radius="md" src={ImagePlaceHolder} alt="1" />
            </Carousel.Slide>
          </>
        ) : (
          slides
        )}
      </Carousel>
    </Container>
  );
};

export default OnTheJobContent;

// How to have rounded borders for iframe
// https://stackoverflow.com/a/36110063/20341251
const RoundedFrame = styled.div`
  display: block;
  overflow: hidden;
  height: 500px;
  border-radius: 0.5rem;
  transform: translateZ(0px);
  border: 0;
`;
