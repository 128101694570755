import React from "react";
import styled from "@emotion/styled";
import { Carousel } from "@mantine/carousel";
import {
  ActionIcon,
  ActionIconProps,
  Button,
  rem,
  Container,
  Flex,
  Image,
  Paper,
  PaperProps,
  Text,
  TextInput,
  Title,
  Switch,
} from "@mantine/core";

import { AxiosData } from "../App";
import { IconTrash } from "@tabler/icons-react";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "./ProfileEdit";
import { VimeoResponse, typeOfUrl } from "../common/common";

import PROCESSING_IMAGE from "../icons/unverified_content.png";
import axios from "axios";
import getVideoId from "get-video-id";

const EMPTY_IMAGE =
  "https://uploads-ssl.webflow.com/6387bddd3f80a97b844d3fa8/6498964770b2f686afaf669a_otj-ph.png";

interface Props {
  data: AxiosData["on_the_job"];
  form: UseFormReturnType<FormValues>;
}

const OnTheJobContent: React.FC<Props> = ({ data, form }) => {
  const [addUrl, setAddUrl] = React.useState("");
  const [isVideoLink, setIsVideoLink] = React.useState(true);

  const slides = form.values.on_the_job.map((mediaItem, index) => {
    return (
      <Carousel.Slide key={index}>
        <CarouselItem
          {...mediaItem}
          onDeleteItem={() => form.removeListItem("on_the_job", index)}
        />
      </Carousel.Slide>
    );
  });

  return (
    <Container size="lg" mt={200}>
      <Flex justify="space-between" align="end" mb={40}>
        <Flex direction="column">
          <Title size="h1" fw="bold" c="#3e4066">
            On the job
          </Title>
          <Text size="sm" c="#73769a">
            Short, fun, informal video clips or selfies of yourself in action at
            the workplace!
          </Text>
        </Flex>
        <Flex align="center">
          <Switch
            size="sm"
            label="Is a video URL?"
            mr={10}
            labelPosition="left"
            checked={isVideoLink}
            onChange={(e) => setIsVideoLink(e.currentTarget.checked)}
          />
          <TextInput
            placeholder="Add url"
            radius="md"
            label=""
            value={addUrl}
            w={300}
            mr={5}
            onChange={(e) => setAddUrl(e.currentTarget.value)}
          />
          <Button
            onClick={async () => {
              let vimeoData;
              if (addUrl.includes("https://vimeo.com/")) {
                // https://developer.vimeo.com/api/oembed/videos
                vimeoData = await axios.get<VimeoResponse>(
                  `https://vimeo.com/api/oembed.json?url=https%3A%2F%2Fvimeo.com%2F${
                    getVideoId(addUrl).id
                  }`
                );
              }
              form.insertListItem("on_the_job", {
                content_type: isVideoLink ? "video" : "image",
                thumbnail: typeOfUrl(addUrl, vimeoData).thumbnail,
                title: null,
                url: typeOfUrl(addUrl, vimeoData).url,
                is_verified: false,
              });
              setAddUrl("");
            }}
            disabled={!addUrl}
          >
            Add
          </Button>
        </Flex>
      </Flex>

      <Carousel
        withIndicators
        height={500}
        loop
        slideSize="33.333333%"
        slideGap="md"
        align="start"
        breakpoints={[
          { maxWidth: "md", slideSize: "50%" },
          { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
        ]}
      >
        {form.values["on_the_job"].length === 0 ? (
          <>
            <Carousel.Slide>
              <Image radius="md" height="500px" src={EMPTY_IMAGE} alt="1" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Image radius="md" height="500px" src={EMPTY_IMAGE} alt="1" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Image radius="md" height="500px" src={EMPTY_IMAGE} alt="1" />
            </Carousel.Slide>
            <Carousel.Slide>
              <Image radius="md" height="500px" src={EMPTY_IMAGE} alt="1" />
            </Carousel.Slide>
          </>
        ) : (
          slides
        )}
      </Carousel>
    </Container>
  );
};

export default OnTheJobContent;

interface CarouselItemProps {
  title: string;
  is_verified: boolean;
  content_type: "image" | "video";
  url: string;
  onDeleteItem: () => unknown;
}

function CarouselItem({
  is_verified,
  content_type,
  title,
  url,
  onDeleteItem,
}: CarouselItemProps) {
  return (
    <MediaContainer>
      <RoundedFrame>
        <DeleteButton radius="lg" onClick={onDeleteItem}>
          <IconTrash color="red" width="2rem" />
        </DeleteButton>
        {!is_verified ? (
          <UnverifiedMedia>
            <Image radius="md" src={PROCESSING_IMAGE} alt="image upload" />
          </UnverifiedMedia>
        ) : content_type === "video" ? (
          <iframe
            src={is_verified === true ? typeOfUrl(url).url : PROCESSING_IMAGE}
            height="500px"
            // width="100%"
            style={{ borderWidth: "0" }}
            allowFullScreen
            title={title}
          />
        ) : (
          <Image
            radius="md"
            src={is_verified === true ? typeOfUrl(url).url : PROCESSING_IMAGE}
            alt="image upload"
          />
        )}
      </RoundedFrame>
    </MediaContainer>
  );
}

interface ActionButtonProps extends ActionIconProps {
  onClick: () => void;
}

const DeleteButton = styled(ActionIcon)<ActionButtonProps>`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
`;

const MediaContainer = styled(Paper)<PaperProps>`
  position: relative;
  height: 500px;
  /* width: ${rem(360)}; */
  display: flex;
  flex-direction: "column";
  justify-content: "space-between";
  align-items: "flex-start";
  background-size: "cover";
  background-position: "center";
  border-radius: 15px;
`;

const UnverifiedMedia = styled.div`
  position: relative;
`;

// How to have rounded borders for iframe
// https://stackoverflow.com/a/36110063/20341251
const RoundedFrame = styled.div`
  display: block;
  overflow: hidden;
  height: 500px;
  border-radius: 0.5rem;
  transform: translateZ(0px);
  border: 0;
`;
