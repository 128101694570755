import {
  Avatar,
  Button,
  Flex,
  Modal,
  Container,
  TextInput,
  Title,
  Text,
  Textarea,
  SimpleGrid,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";
import axios from "axios";

import { AxiosData } from "./App";
import ProfileImagePlaceholder from "./ProfileImagePlaceholder.png";
import { isValidPhoneNumber, sanitizePhoneNumber } from "./utilities";

export type FormValues = z.infer<typeof formSchema>;
type TransformFormValuesFunction = (values: FormValues) => any;

const formSchema = z.object({
  message: z.string().min(1, { message: "Required" }),
  firstName: z.string().min(1, { message: "Required" }).max(255),
  lastName: z.string().min(1, { message: "Required" }).max(255),
  email: z.string().email({ message: "Invalid email address" }),
  phoneNumber: z
    .string()
    .min(1, { message: "Required" })
    .max(255)
    .refine(isValidPhoneNumber, "Must be a valid phone number"),
});

interface Props {
  isOpen: boolean;
  onClose: () => unknown;
  technicianName: string;
  technicianEmail: string;
  profilePic?: AxiosData["profile_image"];
  isVerifiedProfilePic?: AxiosData["isVerifiedProfilePic"];
}

const ContactTechnicianModal: React.FC<Props> = ({
  isOpen,
  technicianName,
  technicianEmail,
  profilePic,
  isVerifiedProfilePic,
  onClose,
}) => {
  const form = useForm<FormValues, TransformFormValuesFunction>({
    initialValues: {
      message: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    transformValues: (values) => ({
      technicianEmail: technicianEmail,
      technicianName: technicianName,
      firstName: values.firstName,
      lastName: values.lastName,
      message: values.message,
      email: values.email,
      phoneNumber:
        values.phoneNumber === ""
          ? null
          : sanitizePhoneNumber(values.phoneNumber),
    }),
    validate: zodResolver(formSchema),
    validateInputOnChange: true,
  });

  function handleSubmit() {
    if (!form.isValid()) return;
    sendTechnicianContactMessage(form.getTransformedValues());
  }

  function resetState() {
    form.reset();
    onClose();
  }

  const sendTechnicianContactMessage = (values: FormValues) => {
    axios
      .post(
        "https://api.retool.com/v1/workflows/6f461bee-e3d7-4cdd-b7db-e6db74833109/startTrigger?workflowApiKey=retool_wk_58ab1634cee14930b155ebb85706f6c4",
        {
          ...values,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resetState();
        window.alert("Your message has been sent");
      })
      .catch(function (error) {
        window.alert("Error, the form submission did not complete");
        console.error(error);
      });
  };

  return (
    <Modal
      opened={isOpen}
      size={500}
      radius="lg"
      closeOnEscape={false}
      closeOnClickOutside={false}
      onClose={() => resetState()}
    >
      <Container size="lg" p={15} pt={0}>
        <Flex align="center" direction="column" mb="md">
          <Avatar
            alt="Technician Profile Image"
            radius="lg"
            size={150}
            mb="sm"
            src={
              (isVerifiedProfilePic && profilePic) || ProfileImagePlaceholder
            }
          />
          <Title size={30} fw={400} mb={15} c="#3e4066">
            Message {technicianName}
          </Title>
          <Text size="sm" color="#6a6d93">
            Got questions regarding your upcoming gig&#63;
          </Text>
          <Text size="sm" color="#6a6d93">
            Get in touch, our freelancers usually reply within 1 business day.
          </Text>
        </Flex>
        <form onSubmit={form.onSubmit(() => handleSubmit())}>
          <Flex align="stretch" direction="column">
            <Textarea
              w="100%"
              label="Message"
              placeholder={`Write your message to ${technicianName} here...`}
              minRows={8}
              mb="sm"
              {...form.getInputProps("message")}
            />
            <Title size={18} fw={300} mb={5} c="#3e4066">
              Your Contact Details
            </Title>
            <SimpleGrid cols={2}>
              <TextInput
                label="First Name"
                withAsterisk
                mb="sm"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                label="Last Name"
                withAsterisk
                mb="sm"
                {...form.getInputProps("lastName")}
              />
              <TextInput
                label="Email"
                withAsterisk
                mb="sm"
                {...form.getInputProps("email")}
              />
              <TextInput
                label="Phone Number"
                withAsterisk
                mb="sm"
                {...form.getInputProps("phoneNumber")}
              />
            </SimpleGrid>
            <Button
              size="md"
              pr={50}
              pl={50}
              mt="xl"
              type="submit"
              radius="md"
              styles={(theme) => ({
                root: {
                  color: "#3e4066",
                  backgroundColor: "#00f85d",
                  "&:hover": theme.fn.hover({
                    backgroundColor: theme.fn.lighten("#00f85d", 0.3),
                  }),
                },
              })}
              disabled={!form.isValid()}
            >
              Send
            </Button>
          </Flex>
        </form>
      </Container>
    </Modal>
  );
};

export default ContactTechnicianModal;
