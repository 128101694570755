import { Container, Flex, Text } from "@mantine/core";

import { StyledSection } from "./BookingDetails";

interface Props {
  address: string;
}

const AddressDetails: React.FC<Props> = ({ address }) => {
  return (
    <StyledSection title="Address">
      <Container size="lg" p={15} pt={25}>
        <Flex direction="column">
          <Text mb="xl" c="#73769a">
            {address}
          </Text>
        </Flex>
      </Container>
    </StyledSection>
  );
};

export default AddressDetails;
