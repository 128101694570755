import React from "react";
import { Accordion, Badge, Container, Title } from "@mantine/core";

interface ToolItem {
  id: string;
  name: string;
}

interface ToolSectionProps {
  tool?: ToolItem[];
}

const ToolSection: React.FC<ToolSectionProps> = ({ tool }) => {
  return (
    <Container size="md" pt={50}>
      <Accordion
        chevronPosition="left"
        styles={{
          item: {
            // styles added to all items
            borderRadius: "15px",
            backgroundColor: "#f1f2f4",
            border: `none`,

            // styles added to expanded item
            "&[data-active]": {
              backgroundColor: "#f1f2f4",
            },
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
          },
          control: {
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
            svg: {
              width: "2rem",
            },
          },
        }}
      >
        <Accordion.Item value="tool">
          <Accordion.Control>
            <Title size="h1" fw={300} color={"#3e4066"}>
              Tools
            </Title>
          </Accordion.Control>
          <Accordion.Panel>
            {tool?.map((item) => (
              <Badge
                key={item.id}
                color="dark"
                size="xl"
                variant="light"
                mr={10}
                mb={10}
                pt={20}
                pb={20}
                sx={{
                  color: "#3e4066",
                  textTransform: "capitalize",
                  backgroundColor: "#FFFFFF",
                  fontWeight: 500,
                  minWidth: "100px",
                }}
              >
                {item.name}
              </Badge>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default ToolSection;
