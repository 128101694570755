const NOW = new Date();

export const TOMORROW_9AM = new Date(
  NOW.getFullYear(),
  NOW.getMonth(),
  NOW.getDate() + 1,
  9,
  0,
  0
);

export const TOMORROW_5PM = new Date(
  NOW.getFullYear(),
  NOW.getMonth(),
  NOW.getDate() + 1,
  17,
  0,
  0
);

export function getTimezoneAbbr(date: Date) {
  return new Intl.DateTimeFormat("en-us", { timeZoneName: "short" })
    .formatToParts(date)
    .find((part) => part.type === "timeZoneName")?.value;
}

export function isValidPhoneNumber(input: string) {
  const format = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return format.test(input);
}

export function sanitizePhoneNumber(input: string) {
  return input.replace(/\D+/g, "");
}

export function formatPhoneNumber(input: string) {
  return input
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
}
