import {
  Avatar,
  Alert,
  Card,
  Container,
  Indicator,
  Image,
  Flex,
  Title,
  Text,
} from "@mantine/core";
import { IconCheck, IconMapPin, IconAlertCircle } from "@tabler/icons-react";

import ProfileImagePlaceholder from "./ProfileImagePlaceholder.png";
import { AxiosData } from "./App";

interface Props {
  technicianName: string;
  location?: string;
  isUnconfirmedBooking: boolean;
  techData?: AxiosData;
}

const ContactDetails: React.FC<Props> = ({
  technicianName,
  location,
  isUnconfirmedBooking,
  techData,
}) => {
  return (
    <Container size="lg" m="30px 80px" p={15}>
      <Card shadow="sm" radius="lg" padding={30} withBorder pos="relative">
        <Card.Section bg="#111827" h="100px">
          <Image src="" />
        </Card.Section>
        <Flex direction="column" align="center">
          <Indicator
            size={20}
            color="#00f85d"
            mt="-60px"
            label={<IconCheck color="#3e4066" width="1.2rem" />}
          >
            <Avatar
              src={
                (techData &&
                  techData.isVerifiedProfilePic &&
                  techData.profile_image) ||
                ProfileImagePlaceholder
              }
              radius="lg"
              size={160}
              mb="xl"
            />
          </Indicator>
          <Title size="36" fw={600} mb={8} c="#3e4066">
            {technicianName}
          </Title>
          <Flex direction="row" align="center" justify="center" mb={10}>
            <IconMapPin width="1.3rem" color="#73769a" />
            <Text size="md" fw={500} ml={4} color="#73769a">
              {location}
            </Text>
          </Flex>
          {isUnconfirmedBooking && (
            <Alert
              title="An email is on the way"
              mt={10}
              c="#73769a"
              bg="#f5fafe"
              sx={{ borderColor: "#baddf9" }}
              icon={<IconAlertCircle color="#4aacf2" />}
            >
              <Text c="#6a6d93" component="p" mt="0">
                You&apos;ll receive an email from us if {technicianName} is
                available for your gig. If {technicianName} is unavailable
                Conneq will find available techs recommended by Justin for your
                gig.
              </Text>
            </Alert>
          )}
        </Flex>
      </Card>
    </Container>
  );
};

export default ContactDetails;
