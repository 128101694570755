import React from "react";
import {
  Badge,
  Button,
  CloseButton,
  Container,
  Flex,
  TextInput,
  Title,
  Accordion,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "./ProfileEdit";

interface ToolItem {
  id: string;
  name: string;
}

interface ToolSectionProps {
  tool?: ToolItem[];
  form: UseFormReturnType<FormValues>;
}

const EditToolSection: React.FC<ToolSectionProps> = ({ form }) => {
  const [addTool, setAddTool] = React.useState("");

  return (
    <Container size="md" pt={50}>
      <Accordion
        chevronPosition="left"
        defaultValue="tool"
        styles={{
          item: {
            // styles added to all items
            borderRadius: "15px",
            backgroundColor: "#f1f2f4",
            border: `none`,

            // styles added to expanded item
            "&[data-active]": {
              backgroundColor: "#f1f2f4",
            },
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
          },
          control: {
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
            svg: {
              width: "2rem",
            },
          },
        }}
      >
        <Accordion.Item value="tool">
          <Flex>
            <Accordion.Control>
              <Title size="h1" fw={300} mb={20} color={"#3e4066"}>
                Tools
              </Title>
            </Accordion.Control>
            <Flex align="center" mr={30}>
              <Flex>
                <TextInput
                  placeholder="Add tool"
                  radius="md"
                  label=""
                  value={addTool}
                  w={315}
                  mr={10}
                  mb={20}
                  onChange={(e) => setAddTool(e.currentTarget.value)}
                />
                <Button
                  onClick={() => {
                    form.insertListItem("tools", addTool);
                    setAddTool("");
                  }}
                  disabled={!addTool}
                >
                  Add
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Accordion.Panel>
            {form.values.tools?.map((item, index) => (
              <Badge
                key={`${item} ${index}`}
                color="dark"
                size="xl"
                variant="light"
                mr={10}
                mb={10}
                pt={20}
                pb={20}
                sx={{
                  color: "#3e4066",
                  textTransform: "capitalize",
                  backgroundColor: "#FFFFFF",
                  fontWeight: 500,
                  minWidth: "100px",
                }}
                rightSection={
                  <CloseButton
                    aria-label="Delete item"
                    mr={-10}
                    color="dark"
                    onClick={() => form.removeListItem("tools", index)}
                  />
                }
              >
                {item}
              </Badge>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default EditToolSection;
