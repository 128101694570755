import React from "react";
import NameContent from "./NameContent";
import BioContent from "./BioContent";
import RoleSection from "./RoleSection";
import ToolSection from "./ToolSection";
import SkillsSection from "./SkillsSection";
import ContactSection from "./ContactSection";
import OnTheJobContent from "./OnTheJobContent";
import PortfolioSection from "./PortfolioSection";

import axios from "axios";
import { LoadingOverlay } from "@mantine/core";
import { useParams } from "react-router-dom";

export interface AxiosData {
  isMigratedOver: boolean;
  userId: string;
  email: string;
  bio: {
    born: string;
    fact: string;
    language: string;
    lives: string;
    obsessions: string;
    pets: string;
    song: string;
    time: string;
  };
  tools: string[];
  skills: string[];
  first: string;
  last: string;
  location: string;
  on_the_job: {
    content_type: "image" | "video";
    thumbnail: string;
    title: string;
    url: string;
    is_verified: boolean;
  }[];
  portfolio: {
    content_type: "image" | "video";
    thumbnail: string;
    title: string;
    url: string;
    is_verified: boolean;
    width: number;
    height: number;
  }[];
  profile_image: string;
  isVerifiedProfilePic: boolean;
  quote: string;
  roles: string[];
}

const App: React.FC = () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<AxiosData>();

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        // V1 api
        // "https://api.retool.com/v1/workflows/4ca5807a-f8b3-4bdb-a0ed-ed91b7835bf0/startTrigger?workflowApiKey=retool_wk_bba8b483a34e408b89fca906847153eb",
        "https://api.retool.com/v1/workflows/21997429-4eb4-45ad-b847-d131d059ad54/startTrigger?workflowApiKey=retool_wk_08cbedac29c44f7ab892e9d9ac1b61a3",
        {
          slug,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const user = response.data;
        console.log(user);
        setData(user);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  // Insert check to render correct page
  return (
    <>
      <NameContent data={data} />
      <BioContent
        data={data}
        profilePic={data?.profile_image}
        isVerifiedProfilePic={data?.isVerifiedProfilePic}
      />
      <OnTheJobContent
        data={data?.on_the_job}
        isMigratedOver={data?.isMigratedOver}
      />
      <RoleSection
        roles={data?.roles.map((role, index) => ({
          name: role,
          id: `${role}${index}`,
        }))}
      />
      <SkillsSection
        skills={data?.skills.map((skill, index) => ({
          name: skill,
          id: `${skill}${index}`,
        }))}
      />
      <ToolSection
        tool={data?.tools.map((tool, index) => ({
          name: tool,
          id: `${tool}${index}`,
        }))}
      />
      <PortfolioSection
        data={data?.portfolio}
        isMigratedOver={data?.isMigratedOver}
      />
      <ContactSection />
    </>
  );
};

export default App;
