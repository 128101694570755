import React from "react";
import styled from "@emotion/styled";
import { IconMapPinFilled, IconDeviceFloppy } from "@tabler/icons-react";
import { Button, Container, Grid, Text } from "@mantine/core";
import { AxiosData } from "../App";

interface EditNameContentProps {
  data?: AxiosData;
  isLoading: boolean;
}
const EditNameContent: React.FC<EditNameContentProps> = ({
  data,
  isLoading = false,
}) => {
  return (
    <Landing>
      <Container size="lg" pt={150} pb={50}>
        <Grid gutter="lg">
          <Grid.Col sm={6} span={12}>
            <NameHeader>
              <Text sx={{ lineHeight: 1.2 }}>Hi, I’m {data?.first || ""}</Text>
            </NameHeader>
            <Location>
              <IconMapPinFilled height={25} />{" "}
              <Text ml={10}>{data?.location}</Text>
            </Location>
            <Button
              radius="md"
              size="lg"
              variant="outline"
              color="white"
              sx={{ borderColor: "#fff", color: "#fff" }}
              leftIcon={<IconDeviceFloppy height={18} />}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Save changes"}
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Landing>
  );
};

export default EditNameContent;

const Landing = styled.div`
  background-color: #262626;
  color: white;
  width: 100vw;
`;

const NameHeader = styled.h1`
  font-size: 70px;
  margin: 0;
  margin-bottom: 25px;
`;
const Location = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 40px;
`;
