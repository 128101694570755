import React from "react";
import {
  Badge,
  Button,
  CloseButton,
  Container,
  Flex,
  TextInput,
  Title,
  Accordion,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "./ProfileEdit";

interface Skill {
  id: string;
  name: string;
}

interface EditSkillsSectionProps {
  skills?: Skill[];
  form: UseFormReturnType<FormValues>;
}

const EditSkillsSection: React.FC<EditSkillsSectionProps> = ({ form }) => {
  const [addSkill, setAddSkill] = React.useState("");
  return (
    <Container size="md" pt={60}>
      <Accordion
        chevronPosition="left"
        defaultValue="skills"
        styles={{
          item: {
            // styles added to all items
            borderRadius: "15px",
            backgroundColor: "#f1f2f4",
            border: `none`,

            // styles added to expanded item
            "&[data-active]": {
              backgroundColor: "#f1f2f4",
            },
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
          },
          control: {
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: "#f1f2f4",
            },
            svg: {
              width: "2rem",
            },
          },
        }}
      >
        <Accordion.Item value="skills">
          <Flex>
            <Accordion.Control>
              <Title size="h1" fw={300} mb={20} color={"#3e4066"}>
                Skills
              </Title>
            </Accordion.Control>
            <Flex align="center" mr={30}>
              <Flex>
                <TextInput
                  placeholder="Add skill"
                  radius="md"
                  label=""
                  value={addSkill}
                  w={315}
                  mr={10}
                  mb={20}
                  onChange={(e) => setAddSkill(e.currentTarget.value)}
                />
                <Button
                  onClick={() => {
                    form.insertListItem("skills", addSkill);
                    setAddSkill("");
                  }}
                  disabled={!addSkill}
                >
                  Add
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Accordion.Panel>
            {form.values.skills.map((skill, index) => (
              <Badge
                key={`${skill} ${index}`}
                color="green"
                size="xl"
                variant="filled"
                mr={10}
                mb={10}
                pt={20}
                pb={20}
                sx={{
                  color: "#3e4066",
                  textTransform: "capitalize",
                  backgroundColor: "#EEFFF0",
                  fontWeight: 500,
                  minWidth: "100px",
                }}
                rightSection={
                  <CloseButton
                    aria-label="Delete item"
                    mr={-10}
                    color="dark"
                    onClick={() => form.removeListItem("skills", index)}
                  />
                }
              >
                {skill}
              </Badge>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default EditSkillsSection;
