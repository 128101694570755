import React from "react";
import styled from "@emotion/styled";
import { IconMessage, IconMapPinFilled } from "@tabler/icons-react";
import { Button, Container, Flex, Grid, Loader, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import {
  ROLE_STEP_NAME,
  GIG_STEP_NAME,
  CONTACT_STEP_NAME,
  GIG_ROLES,
} from "./BookingModal/constants";
import BookingModal, { FormValues } from "./BookingModal/BookingModal";
import { ConfirmBookingModal } from "./ConfirmBookingModal";
import ContactTechnicianModal from "./ContactTechnicianModal";
import { AxiosData } from "./App";
import moment from "moment";

interface NameContentProps {
  data?: AxiosData;
}

const NameContent: React.FC<NameContentProps> = ({ data }) => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [bookingModalOpened, bookingModalHandler] = useDisclosure(false);
  const [confirmBookingModalOpened, confirmBookingModalHandler] =
    useDisclosure(false);
  const [contactTechnicianModalOpened, contactTechnicianModalHandler] =
    useDisclosure(false);

  const navigate = useNavigate();

  if (!data) return <Landing></Landing>;

  const sendBookingData = (values: FormValues) => {
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    setIsLoading(true);
    axios
      .post(
        "https://api.retool.com/v1/workflows/446b80e5-868b-4507-b181-75116abf2ac9/startTrigger?workflowApiKey=retool_wk_d3365938ecd748c4aee497f76a4ab388",
        {
          // organizationId: "04e064ad-e937-4d4a-b50e-a3f69b1f9d96", // test-no-alerts org

          organizationId: "fe4ac591-9203-4b0a-b180-d14fadb9e61a", // Conneq Booking org
          username: data.email,
          customer: {
            email: values.contactStep.email,
            firstName: values.contactStep.firstName,
            lastName: values.contactStep.lastName,
            phoneNumber: values.contactStep.phoneNumber,
          },
          address: {
            street: values.gigStep.addressLine1,
            city: values.gigStep.city,
            state: values.gigStep.state,
            zip: values.gigStep.zipCode,
          },
          name: values.gigStep.gigName,
          description: `This gig was created through profile booking.\n\n Name: ${values.contactStep.firstName} ${values.contactStep.lastName}\n Email: ${values.contactStep.email}\n Phone #: ${values.contactStep.phoneNumber}`,
          type: "PHYSICAL",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          location: `${values.gigStep.addressLine1} ${values.gigStep.addressLine2}, ${values.gigStep.city} ${values.gigStep.state} ${values.gigStep.zipCode}`,
          dailyRate: values.roleStep.dailyBudget * 100,
          userId: data?.userId,
          smsMessage: `CONNEQ Profile Booking Request\n\nHi ${
            data.first
          },\n\nRole: ${
            GIG_ROLES.find((role) => role.id === values.roleStep.roleId)?.name
          }\nEvent: ${values.gigStep.gigName}\nCoordinator: ${
            values.contactStep.firstName
          } ${values.contactStep.lastName}\nDate(s): ${values.roleStep.shifts
            .map((shift) => moment(shift.startsAt).format("ddd MMM DD YYYY"))
            .join(", ")}\nLocation: ${values.gigStep.addressLine1} ${
            values.gigStep.addressLine2
          }, ${values.gigStep.city} ${values.gigStep.state} ${
            values.gigStep.zipCode
          }\nPay: ${USDollar.format(
            values.roleStep.dailyBudget
          )}\n\nFor more questions contact the gig coordinator, ${
            values.contactStep.firstName
          } ${values.contactStep.lastName}, at ${values.contactStep.email} or ${
            values.contactStep.phoneNumber
          }\n\nTo accept or decline, click here:`,
          jobs: {
            gigRoleId: values.roleStep.roleId,
            maxDailyRate: values.roleStep.dailyBudget * 100,
            shifts: values.roleStep.shifts.map((shift) => ({
              date: moment(shift.startsAt).format("YYYY-MM-DD"),
              endTime: moment(shift.endsAt).format("HH:mm"),
              inPerson: true,
              notes: values.roleStep.specialRequests,
              startTime: moment(shift.startsAt).format("HH:mm"),
            })),
          },
          dates: values.roleStep.shifts.map((shift) =>
            moment(shift.startsAt).format("YYYY-MM-DD")
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.data.redirect) {
          navigate(
            `/${slug}/${response.data.createGigData.createGig.gig.id}/unavailable`
          );
          return;
        }
        setIsLoading(false);
        bookingModalHandler.close();
        console.log("create rsvp response: ", response);
        navigate(`/${slug}/${response.data.createGigData.createGig.gig.id}`);
      })
      .catch(function (error) {
        window.alert("Error, the booking did not complete");
        setIsLoading(false);
        console.error("error: ", error);
      });
  };
  return (
    <Landing>
      <Container size="lg" pt={100} pb={50}>
        <Grid gutter="lg">
          <Grid.Col sm={7} span={12}>
            <NameHeader>
              <Text sx={{ lineHeight: 1.2 }}>Hi, I’m {data?.first}</Text>
            </NameHeader>
            <Location>
              <IconMapPinFilled height={25} />
              <Text ml={10}>{data?.location}</Text>
            </Location>
            <Flex>
              {!isLoading ? (
                <Button
                  size="lg"
                  radius="md"
                  mr="30px"
                  styles={(theme) => ({
                    root: {
                      color: "#262626",
                      backgroundColor: "#00f85d",
                      "&:hover": theme.fn.hover({
                        backgroundColor: theme.fn.lighten("#00f85d", 0.3),
                      }),
                    },
                  })}
                  onClick={bookingModalHandler.open}
                >
                  Book now
                </Button>
              ) : (
                <Button
                  disabled
                  size="lg"
                  radius="md"
                  mr="30px"
                  w="138px"
                  styles={(theme) => ({
                    root: {
                      color: "#262626",
                      backgroundColor: "#00f85d",
                      "&:hover": theme.fn.hover({
                        backgroundColor: theme.fn.lighten("#00f85d", 0.3),
                      }),
                    },
                  })}
                >
                  <Flex justify="center" align="center">
                    <Loader size="sm" />
                  </Flex>
                </Button>
              )}
              <Button
                radius="md"
                size="lg"
                variant="outline"
                color="white"
                sx={{ borderColor: "#fff", color: "#fff" }}
                leftIcon={<IconMessage height={18} />}
                onClick={contactTechnicianModalHandler.open}
              >
                Message
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col sm={5} span={12}>
            <Text fz="38px" fw={700} ml={30} c="#515151" lh="53px">
              {data?.quote && `"${data.quote}"`}
            </Text>
          </Grid.Col>
        </Grid>
      </Container>
      <BookingModal
        requiredSteps={[ROLE_STEP_NAME, GIG_STEP_NAME, CONTACT_STEP_NAME]}
        isOpen={bookingModalOpened}
        onClose={bookingModalHandler.close}
        onFinished={(values) => {
          console.log("Booking Form Values: ", values);
          sendBookingData(values);
        }}
      />
      {data && (
        <ConfirmBookingModal
          data={data}
          isOpen={confirmBookingModalOpened}
          onClose={confirmBookingModalHandler.close}
          onFinished={() => {}}
        />
      )}
      <ContactTechnicianModal
        technicianName={`${data.first} ${data.last}`}
        technicianEmail={data.email}
        isOpen={contactTechnicianModalOpened}
        onClose={contactTechnicianModalHandler.close}
        profilePic={data?.profile_image}
        isVerifiedProfilePic={data?.isVerifiedProfilePic}
      />
    </Landing>
  );
};

export default NameContent;

const Landing = styled.div`
  background-color: #262626;
  color: white;
  width: 100vw;
`;

const NameHeader = styled.h1`
  font-size: 70px;
  margin: 0;
  margin-bottom: 25px;
  line-height: 1;
`;
const Location = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 40px;
`;
