import { Container, Flex, SimpleGrid, Text } from "@mantine/core";

import { StyledSection } from "./BookingDetails";
import { formatPhoneNumber } from "../utilities";

interface Props {
  name: string;
  email: string;
  phoneNumber: string;
}

const ContactDetails: React.FC<Props> = ({ name, email, phoneNumber }) => {
  return (
    <StyledSection title="Contact Information">
      <Container size="lg" p={15} pt={25}>
        <SimpleGrid cols={2}>
          <Flex direction="column">
            <Text size="xs" fw={700} mb={5} c="#3e4066">
              Name
            </Text>
            <Text mb="xl" c="#73769a">
              {name}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text size="xs" fw={700} mb={5} c="#3e4066">
              Email
            </Text>
            <Text mb="xl" c="#73769a">
              {email}
            </Text>
            <Text size="xs" fw={700} mb={5} c="#3e4066">
              Phone
            </Text>
            <Text mb="md" c="#73769a">
              {formatPhoneNumber(phoneNumber)}
            </Text>
          </Flex>
        </SimpleGrid>
      </Container>
    </StyledSection>
  );
};

export default ContactDetails;
