import { Modal, Stepper } from "@mantine/core";
import { useStateList } from "react-use";
import { BookingStepName } from "./constants";
import RoleStep from "./RoleStep";
import GigStep from "./GigStep";
import ContactStep from "./ContactStep";
import { useForm, zodResolver } from "@mantine/form";
import { TOMORROW_9AM, TOMORROW_5PM } from "../utilities";
import { z } from "zod";

import { isValidPhoneNumber, sanitizePhoneNumber } from "../utilities";
import React from "react";
import axios from "axios";

const formSchema = z.object({
  organizationId: z.string(),
  type: z.string(),
  timezone: z.string(),
  roleStep: z.object({
    roleId: z.string().min(1, { message: "Required" }),
    dailyBudget: z.number().nonnegative(),
    specialRequests: z.string(),
    shifts: z.array(
      z
        .object({
          startsAt: z.coerce.date(),
          endsAt: z.coerce.date(),
        })
        .refine((data) => data.endsAt > data.startsAt, {
          message: "End date cannot be earlier than start date.",
          path: ["endsAt"],
        })
        .refine((data) => data.startsAt > new Date(), {
          message: "Start date must be in the future.",
          path: ["startsAt"],
        })
        .refine((data) => data.endsAt > new Date(), {
          message: "End date must be in the future.",
          path: ["endsAt"],
        })
    ),
  }),
  gigStep: z.object({
    gigName: z.string().min(1, { message: "Required" }),
    addressLine1: z.string().min(1, { message: "Required" }).max(45),
    addressLine2: z.string().optional(),
    city: z.string().min(1, { message: "Required" }).max(45),
    state: z.string().min(1, { message: "Required" }).max(45),
    zipCode: z.string().min(1, { message: "Required" }).max(45),
  }),
  contactStep: z.object({
    firstName: z.string().min(1, { message: "Required" }).max(255),
    lastName: z.string().min(1, { message: "Required" }).max(255),
    email: z.string().email({ message: "Invalid email address" }),
    phoneNumber: z
      .string()
      .min(1, { message: "Required" })
      .max(255)
      .refine(isValidPhoneNumber, "Must be a valid phone number"),
  }),
});

export type FormValues = z.infer<typeof formSchema>;
type TransformFormValuesFunction = (values: FormValues) => any;

interface Props {
  /**
   * Whether or not the modal is currently open.
   */
  isOpen: boolean;
  /**
   * The steps that are required for the user to complete.
   * They will be included in the "total step count" and the stepper will be visible.
   */
  requiredSteps: BookingStepName[];
  /**
   * Invoked when the user completes the final required step.
   */
  onFinished: (values: FormValues) => unknown;
  /**
   * Invoked when the user explicitly closes the modal.
   */
  onClose: () => unknown;
}

const BookingModal: React.FC<Props> = ({
  isOpen,
  requiredSteps,
  onClose,
  onFinished,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get("orgId");
  const [gigRoles, setGigRoles] = React.useState<{name: string, id: string}[]>([])

  const form = useForm<FormValues, TransformFormValuesFunction>({
    initialValues: {
      organizationId: orgId || "",
      timezone: "America/Toronto",
      type: "PHYSICAL",
      roleStep: {
        roleId: "",
        specialRequests: "",
        dailyBudget: 0,
        shifts: [
          {
            startsAt: TOMORROW_9AM,
            endsAt: TOMORROW_5PM,
          },
        ],
      },
      gigStep: {
        gigName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      contactStep: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
    },
    transformValues: (values) => ({
      ...values,
      roleId: values.roleStep.roleId,
      dailyBudget: values.roleStep.dailyBudget * 100,
      specialRequests: values.roleStep.specialRequests,
      shifts: values.roleStep.shifts.map((shift) => ({
        startsAt: shift.startsAt.toISOString(),
        endsAt: shift.endsAt.toISOString(),
      })),
      phoneNumber:
        values.contactStep.phoneNumber === ""
          ? null
          : sanitizePhoneNumber(values.contactStep.phoneNumber),
    }),
    validate: zodResolver(formSchema),
    validateInputOnChange: true,
  });

  // Utilities to help progress the user through the steps
  const {
    next: nextStep,
    prev: prevStep,
    currentIndex: currentStepIndex,
    setStateAt: setCurrentIndex,
  } = useStateList([...requiredSteps]);

  const isLastRequiredStep = currentStepIndex === requiredSteps.length - 1;
  const nextStepText = isLastRequiredStep ? "Book Technician" : "Next Step";

  function goToNextStep() {
    if (isLastRequiredStep) {
      onFinished(form.values);
      resetState();
      return;
    }
    nextStep();
  }

  function goToPreviousStep() {
    prevStep();
  }

  function resetState() {
    form.reset();
    setCurrentIndex(0);
    onClose();
  }

  React.useEffect(() => {
    axios.post("https://api.retool.com/v1/workflows/2de906c4-15bc-4f96-975b-6f47fb8fc957/startTrigger?workflowApiKey=retool_wk_7b91b56e41c244ec8eb002f1ecd87219").then(res => {setGigRoles(res.data.data.gigRoles)})
  },[])

  return (
    <Modal
      opened={isOpen}
      size="md"
      radius="lg"
      closeOnEscape={false}
      closeOnClickOutside={false}
      onClose={() => resetState()}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          onFinished(values);
        })}
      >
        <Stepper
          active={currentStepIndex}
          color="#00f85d"
          size="xs"
          breakpoint="sm"
        >
          <Stepper.Step label="Booking Request">
            <RoleStep
              form={form}
              gigRoles={gigRoles.map(role => ({label: role.name, value: role.id}))}
              nextStepText={nextStepText}
              onNext={goToNextStep}
            />
          </Stepper.Step>
          <Stepper.Step label="Gig Details">
            <GigStep
              form={form}
              nextStepText={nextStepText}
              onPrevious={goToPreviousStep}
              onNext={goToNextStep}
            />
          </Stepper.Step>
          <Stepper.Step label="Contact Information">
            <ContactStep
              form={form}
              nextStepText={nextStepText}
              onPrevious={goToPreviousStep}
              onNext={goToNextStep}
            />
          </Stepper.Step>
        </Stepper>
      </form>
    </Modal>
  );
};

export default BookingModal;
