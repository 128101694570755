import styled from "@emotion/styled";
import {
  Container,
  Flex,
  Image,
  Grid,
  MediaQuery,
  Select,
  Space,
  Title,
  Text,
  Textarea,
  TextInput,
  Button,
} from "@mantine/core";
import { AxiosData } from "../App";
import { UseFormReturnType } from "@mantine/form";
import { FormValues } from "./ProfileEdit";
import React from "react";

const BIRTH_DATA = [
  { label: "50s", value: "50s" },
  { label: "60s", value: "60s" },
  { label: "70s", value: "70s" },
  { label: "80s", value: "80s" },
  { label: "90s", value: "90s" },
  { label: "00s", value: "00s" },
  { label: "10s", value: "10s" },
];

interface EditBioContentProps {
  data?: AxiosData["bio"];
  profilePic?: AxiosData["profile_image"];
  isVerifiedProfilePic?: AxiosData["isVerifiedProfilePic"];
  form: UseFormReturnType<FormValues>;
}

const EditBioContent: React.FC<EditBioContentProps> = ({ form }) => {
  return (
    <MediaQuery largerThan="sm" styles={{ marginTop: "40px" }}>
      <Container size="lg" mt={370}>
        <Grid grow gutter="lg">
          <Grid.Col span={12} md={8}>
            <Title size="h1" fw="bold" mb={20} c="#3e4066">
              Short Bio
            </Title>
            <Grid grow mr={30} gutter="xl" c="#3e4066">
              <Grid.Col span={12} md={12}>
                <Textarea
                  radius="md"
                  label="Quote"
                  w="100%"
                  {...form.getInputProps("quote")}
                />
                <Text fz="xs" fw={500}>
                  Example: When you're curious, you find lots of interesting
                  things to do &#45; Walt Disney
                </Text>
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <BioRow>
                  <Select
                    label="I was born in the"
                    data={BIRTH_DATA}
                    {...form.getInputProps("bio.born")}
                  />
                  <Space h="md" />
                </BioRow>
                <BioRow>
                  <Textarea
                    radius="md"
                    label="Pets"
                    {...form.getInputProps("bio.pets")}
                  />
                  <Text fz="xs" fw={500}>
                    Example: 2 guinea pigs and a boxer
                  </Text>
                </BioRow>
                <BioRow>
                  <Textarea
                    radius="md"
                    label="I spend too much time"
                    {...form.getInputProps("bio.time")}
                  />
                  <Text fz="xs" fw={500}>
                    Example: Watching Netflix
                  </Text>
                </BioRow>
                <BioRow>
                  <Textarea
                    radius="md"
                    label="Fun fact about me"
                    {...form.getInputProps("bio.fact")}
                  />
                  <Text fz="xs" fw={500}>
                    Example: Visited all 50 states
                  </Text>
                </BioRow>
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <BioRow>
                  <TextInput
                    radius="md"
                    label="Hometown"
                    {...form.getInputProps("bio.lives")}
                  />
                  <Text fz="xs" fw={500}>
                    Example: Lincoln, NE
                  </Text>
                </BioRow>
                <BioRow>
                  <Textarea
                    radius="md"
                    label="I'm obsessed with"
                    {...form.getInputProps("bio.obsessions")}
                  />
                  <Text fz="xs" fw={500}>
                    Example: Cycling and bowling
                  </Text>
                </BioRow>
                <BioRow>
                  <Textarea
                    radius="md"
                    label="Favorite song"
                    {...form.getInputProps("bio.song")}
                  />
                  <Text fz="xs" fw={500}>
                    Example: Changes - Yes
                  </Text>
                </BioRow>
                <Textarea
                  radius="md"
                  label="Languages I speak"
                  {...form.getInputProps("bio.language")}
                />
                <Text fz="xs" fw={500}>
                  Example: English, Korean, Mandarin
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={8} md={4}>
            <Image
              withPlaceholder
              radius="md"
              src={
                (form.values.isVerifiedProfilePic &&
                  form.values.profile_image) ||
                ""
              }
              alt="User Profile Image"
              height={460}
            />
            <Flex justify="flex-end">
              <TextInput
                mt={10}
                {...form.getInputProps("profile_image")}
                placeholder="New profile image shared url"
                radius="md"
                w="100%"
              />
              <Button
                ml={5}
                mt={10}
                onClick={() =>
                  form.setFieldValue("isVerifiedProfilePic", false)
                }
              >
                Change
              </Button>
            </Flex>
            {!form.values.isVerifiedProfilePic && form.values.profile_image && (
              <Text mt={15} color={"orange"}>
                Your new profile image is under review. When you are done
                editing, click Save Changes and we will review your new profile
                picture shortly.
              </Text>
            )}
          </Grid.Col>
        </Grid>
      </Container>
    </MediaQuery>
  );
};

export default EditBioContent;

const BioRow = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 20px;
`;
