import {
  Button,
  Container,
  Grid,
  Group,
  Title,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import { UseFormReturnType } from "@mantine/form";

import { FormValues } from "./BookingModal";
import { US_STATES } from "./constants";

interface Props {
  form: UseFormReturnType<FormValues>;
  nextStepText?: string;
  onNext: () => unknown;
  onPrevious: () => unknown;
}

const GigStep: React.FC<Props> = ({
  form,
  nextStepText,
  onNext,
  onPrevious,
}) => {
  const isFormValid =
    form.isValid("gigStep.gigName") &&
    form.isValid("gigStep.addressLine1") &&
    form.isValid("gigStep.addressLine2") &&
    form.isValid("gigStep.city") &&
    form.isValid("gigStep.state") &&
    form.isValid("gigStep.zipCode");

  // Handle user attempting to progress to the next step
  function nextStep() {
    if (!isFormValid) return;

    onNext();
  }

  return (
    <Container size="xl">
      <Title size="h1" mt="md" mb="sm" c="#3e4066">
        Level up your gig
      </Title>
      <Text mb="lg" c="#73769a">
        Tell us some basic information and we'll bring it all together
      </Text>
      <TextInput
        label="Gig Name"
        withAsterisk
        mb="xl"
        {...form.getInputProps("gigStep.gigName")}
      />
      <Text fz={14} mb="sm" c="#73769a">
        Gig Location
      </Text>
      <TextInput
        label="Address"
        withAsterisk
        mb="md"
        {...form.getInputProps("gigStep.addressLine1")}
      />
      <TextInput
        label="Apartment, suite, etc. (optional)"
        mb="md"
        {...form.getInputProps("gigStep.addressLine2")}
      />
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            label="City"
            mb="md"
            {...form.getInputProps("gigStep.city")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            searchable
            label="State"
            placeholder="Select a state ..."
            data={US_STATES}
            withAsterisk
            mb="sm"
            {...form.getInputProps("gigStep.state")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Zip Code"
            mb="md"
            {...form.getInputProps("gigStep.zipCode")}
          />
        </Grid.Col>
      </Grid>
      <Group position="apart" mt="xl">
        <Button
          variant="subtle"
          color="dark"
          onClick={onPrevious}
          leftIcon={<IconArrowNarrowLeft />}
        >
          Back
        </Button>
        <Button
          type="submit"
          styles={(theme) => ({
            root: {
              color: "#3e4066",
              backgroundColor: "#00f85d",
              "&:hover": theme.fn.hover({
                backgroundColor: theme.fn.lighten("#00f85d", 0.3),
              }),
            },
          })}
          disabled={!isFormValid}
          onClick={nextStep}
        >
          {nextStepText}
        </Button>
      </Group>
    </Container>
  );
};

export default GigStep;
